
import { defineComponent, ref, onMounted } from "vue";
// import axios from "axios";
import miGrupoService from "@/services/miGrupo.service";

export default defineComponent({
  name: "team",
  setup() {
    const grupos = ref([]);
    const traerGrupos = () => {
      miGrupoService.listarGrupos().then((data) => {
        grupos.value = data.data.data;
      });
    };
    onMounted(() => {
      traerGrupos();
    });
    return { traerGrupos, grupos };
  },
});
