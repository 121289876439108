<template>
  <div class="row">
    <div class="col-lg-8 col-sm-12">
      <div>
        <div
          class="card p-lg-10 p-sm-3 overflow-auto"
          style="max-height: 880px"
        >
          <div>
            <h2 class="text-blue fw-bolder">Mis invitados</h2>
          </div>
          <div class="d-flex align-items-center my-5">
            <i class="bi bi-search me-5"></i>
            <input type="text" class="form-control w-50" placeholder="Buscar" />
          </div>
          <div class="my-5 h-75">
            <table class="table h-100">
              <thead class="bg-gray-input text-center">
                <tr style="color: #999999">
                  <th scope="col"></th>
                  <th scope="col">Usuario</th>
                  <th scope="col">Grupo</th>
                  <th scope="col">Email</th>
                  <th></th>
                </tr>
              </thead>
              <tbody
                class="text-center"
                v-for="(invitado, i) in invitados"
                :key="i"
              >
                <tr>
                  <th scope="row">
                    <div>
                      <img
                        src="media/avatars/blank.png"
                        class="img-fluid rounded-circle"
                        alt=""
                        style="width: 40px"
                      />
                    </div>
                  </th>
                  <td>{{ invitado.nombre }}</td>
                  <td>Grupo</td>
                  <td>{{ invitado.correo }}</td>
                  <td class="d-flex justify-content-evenly">
                    <button
                      class="btn p-2"
                      data-bs-toggle="modal"
                      data-bs-target="#modalEliminarUsuario"
                      @click="invitadoSelectDelete(invitado._id)"
                    >
                      <i class="bi bi-trash-fill text-blue"></i>
                    </button>
                    <div
                      class="modal fade"
                      id="modalEliminarUsuario"
                      tabindex="-1"
                      aria-labelledby="modalEliminarUsuarioLabel"
                      aria-hidden="true"
                    >
                      <div
                        class="modal-dialog modal-dialog-centered"
                        style="
                          border-radius: 38px;
                          max-height: 321px;
                          max-width: 375px;
                        "
                      >
                        <div class="modal-content p-5">
                          <div class="text-center my-5">
                            <h2 class="text-blue">¿Eliminar?</h2>
                          </div>
                          <div class="text-gray text-center my-5">
                            <p>
                              ¿¡Está seguro de querer eliminar este invitado!?
                            </p>
                          </div>
                          <div class="text-center text-gray my-5">
                            <p>
                              Debe tener en cuenta que al eliminar el invitado
                              el mismo será eliminado de todos los grupos en los
                              que se encuentre .
                            </p>
                          </div>
                          <div class="text-center my-5">
                            <button
                              @click="borrarInvitado"
                              class="btn bg-blue rounded-pill text-white"
                              aria-label="Close"
                              data-bs-dismiss="modal"
                            >
                              Confirmar
                            </button>
                          </div>
                          <div class="text-center">
                            <button
                              type="button"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              class="btn text-blue rounded-pill"
                            >
                              Cancelar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <router-link
                      :to="{
                        name: 'ver-invitados',
                        params: { id: invitado._id },
                      }"
                      class="btn p-2"
                    >
                      <i class="bi bi-pencil-fill text-blue"></i>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="my-3">
            <Paginator
              :rows="usuariosPaginate.limit"
              :totalRecords="usuariosPaginate.total"
              :first="usuariosPaginate.offset"
              @page="onPage"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-sm-12">
      <div class="d-flex justify-content-end mt-2">
        <router-link
          class="btn bg-white text-center mx-2 shadow-sm fw-normal border"
          style="border-radius: 16px"
          to="mi-grupo-agregar-invitado"
        >
          <i class="bi bi-person-fill text-blue"></i>
          Agregar invitado
        </router-link>
        <router-link
          class="btn bg-white text-center shadow-sm fw-normal border"
          style="border-radius: 16px"
          to="mi-grupo-agregar-grupo"
        >
          <i class="bi bi-people-fill text-blue"></i>
          Agregar nuevo grupo
        </router-link>
      </div>
      <div class="my-4">
        <team style="max-height: 400px"></team>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import miGrupoService from "@/services/miGrupo.service";
import team from "@/components/widgets/grupos/GruposListarPerfil.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import Paginator from "primevue/paginator";
import Swal from "sweetalert2";

export default defineComponent({
  name: "listar-invitados",
  components: { team, Paginator },
  setup() {
    const invitados = ref([]);
    const offset = ref();
    const usuariosPaginate = ref({
      lastPage: 0,
      limit: 0,
      offset: 0,
      page: 0,
      total: 0,
    });
    const traerInvitados = (params = {}) => {
      miGrupoService
        .listarUsuarios({
          ...params,
          limit: 10,
        })
        .then(({ data }) => {
          invitados.value = data.data;
          usuariosPaginate.value.lastPage = data.lastPage;
          usuariosPaginate.value.limit = data.limit;
          usuariosPaginate.value.offset = data.offset;
          usuariosPaginate.value.page = data.page;
          usuariosPaginate.value.total = data.total;
        });
    };
    const idInvitado = ref("");
    const invitadoSelectDelete = (value) => {
      idInvitado.value = value;
    };
    const borrarInvitado = () => {
      console.log(idInvitado.value);
      miGrupoService.eliminarUsuarios(idInvitado.value).then((res) => {
        console.log(res);
        Swal.fire("Borrado", "El invitado se ah borrado con exito", "success");
      });
    };
    const onPage = (event) => {
      const page = event.page + 1;
      traerInvitados({ page });
    };
    onMounted(() => {
      setCurrentPageBreadcrumbs("Invitados", ["Pages", "Grupos"]);
      traerInvitados();
    });
    return {
      invitados,
      borrarInvitado,
      usuariosPaginate,
      onPage,
      offset,
      invitadoSelectDelete,
    };
  },
});
</script>
<style scoped>
.border-blue {
  border-color: #27aae1 !important;
}
.text-gray {
  color: #999999;
}
.text-blue {
  color: #27aae1;
}
.bg-blue {
  background-color: #27aae1;
}
.bg-gray-input {
  background-color: #f4f8fb;
}
.listado-info {
  padding: 0;
  list-style: none;
}
.listado-info li label {
  font-size: 1.125rem;
}
.listado-info li p {
  font-size: 1.125rem;
  color: #999999;
}
</style>
